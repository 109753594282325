const SECURE_LOGIN = "rhsbts_rahisibet"
const HASH = "bf55710e3bc11b8b48f00b63dc9c279b"

import Auth from "@/services/auth.js"
import API from "@/services/api.js"
import { md5 } from 'js-md5';
import * as APIENDPOINTS from "../services/api_endpoints"


export const CASINO = {
    AUTH_PARAMS: () =>{
        const params = new URLSearchParams()
        params.append('secureLogin', 'rhsbts_rahisibet')
        params.append('hash', 'bf55710e3bc11b8b48f00b63dc9c279b')
        return params
    
    },

    HEADERS: () =>{
        return {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    },

    getImageUrl: (gameId) =>{  
        return `https://rahisibetsu-dk2.pragmaticplay.net/game_pic/rec/325/${gameId}.png?secureLogin=${SECURE_LOGIN}&hash=${HASH}`;
    },

    async launchGameL(gameId,cur,lang,iso,playMode){
        const user = Auth.getAuthUser();
        let postData = {}
        // let url = "";
    
        postData['country'] = iso;
        postData['language'] = lang;
        postData['platform'] = APIENDPOINTS.platform;
        postData['symbol'] = gameId;
        postData['token'] = user.token;
        postData['currency'] = cur;
        postData['isProduction'] = true;

        if(playMode == 'DEMO') {
            let str = `country=${iso}&currency=${cur}&language=${lang}&lobbyUrl=${APIENDPOINTS.lobbyUrl}&platform=${APIENDPOINTS.platform}&playMode=${playMode}&secureLogin=${APIENDPOINTS.prodSecureLogin}&stylename=${APIENDPOINTS.prodStyleName}&symbol=${gameId}${APIENDPOINTS.prodSecretKey}`;
            let hash = md5(str);
            postData['hash'] = hash;
            postData['playMode'] = playMode;
        } else {
            let str = `country=${iso}&currency=${cur}&language=${lang}&lobbyUrl=${APIENDPOINTS.lobbyUrl}&platform=${APIENDPOINTS.platform}&playMode=REAL&secureLogin=${APIENDPOINTS.prodSecureLogin}&stylename=${APIENDPOINTS.prodStyleName}&symbol=${gameId}&token=${user.token}${APIENDPOINTS.prodSecretKey}`;
            let hash = md5(str);
            postData['hash'] = hash;
            postData['playMode'] = playMode;
        }

       let url = await API.getgameUrl(postData).then( (val) => val.data)
        return url;
    },

    launchGame(gameId,cur){
        const user = Auth.getAuthUser()
        // console.log("Called to launch game",gameId)
        return `https://rahisibetsu-dk2.pragmaticplay.net/gs2c/playGame.do?key=token%3D${user.token}%26cur%3D${cur}%26symbol%3D${gameId}%26technology%3DH5%26platform%3DMOBILE%26language%3Dfr%26cashierUrl%3Dhttps%3A%2F%2F%26lobbyUrl%3Dhttps://rahisibet.com%3A%2F%2F&stylename=rhsbts_rahisibet`
    },
    launchGameLive(gameId,cur,lang,iso){
        const user = Auth.getAuthUser()
       
        return `https://rahisibetsu-dk2.pragmaticplay.net/gs2c/playGame.do?key=token%3D${user.token}%26currency%3D${cur}%26symbol%3D${gameId}%26technology%3DH5%26country%3D${iso}%26platform%3DMOBILE%26language%3D${lang}%26cashierUrl%3Dhttps%3A%2F%2F%26lobbyUrl%3Dhttps://rahisibet.com%3A%2F%2F&stylename=rhsbts_rahisibet`
    }


}
